import { Backdrop, Box, CircularProgress } from '@mui/material';

const MyCircularProgress = () => {
        return (
            <Box sx={{ display: 'flex', width: '100%',height: '100%', justifyContent: 'center'}}>
                <Box sx={{ display: 'flex',height: '100%', flexDirection: 'column', justifyContent: 'center'}}>
    
        
                       <CircularProgress color='info'/>
              
                </Box>
            </Box>
        );
    
    };
    
    export default MyCircularProgress;
