import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import Logo from "../Logo/logo.png"
import MyCircularProgress from '../MyCircularProgress/MyCircularProgress';
export default function PageNotFound() {
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() =>
    {
        setTimeout(()=>{
            setLoading(false);
           }, 1500)
        
        
    }, [])
    return (
        <>
        {loading ? <MyCircularProgress /> : <>
        <Container maxWidth='md' sx={{padding: 2, flex:1, height: '100%', display:'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Box display='flex' justifyContent='center'>
            <Stack
                paddingTop={4}
                paddingBottom={4}
                direction='column'
                width='100%'
                spacing={4}
                sx={{
                    color: 'primary.myBoxShadow',
                }}
            >
                <Box display='flex' justifyContent='center' paddingRight={2}>
                    <img style={{
                        height: 100,

                    }}
                        src={Logo} alt="Logo" />
                </Box>

                <Stack direction='column' spacing={1}>
                    <Typography color='primary.drushimTitle' variant='h2' textAlign='center'>
                        אנו מתנצלים,
                    </Typography>

                    <Typography variant='body2' color='secondary.drushimTitle' textAlign='center'>
                        העמוד אינו נמצא...
                    </Typography>
                </Stack>
            </Stack>

        </Box>
    </Container>
        </>}
        </>

    )
}
