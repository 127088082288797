import { ArticleOutlined, DesktopAccessDisabled, Person } from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Avatar, Box, Button, Container, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CandidatesByFilters from '../../../../Firebase/FirebaseFunctions/Reports/CandidatesFilters';
import { exportToExcel } from '../../../../Firebase/FirebaseFunctions/Reports/GlobalFunctions';
import { getAllRoles, getAllSectors } from '../../../../Firebase/FirebaseFunctions/functionIndex';
import dayjs from "dayjs";
import MyLoading from "../../../../Components/MyLoading/MyLoading";
import { LoadingButton } from "@mui/lab";

export interface typeMyData {
    id: number;
    name: string;
}

export default function CandidatesForm() {
    const [status, setSelectStatus] = React.useState('כל הסטטוסים');
    const [timeOnStatus, setTimeOnStatus] = React.useState('כל המועמדים');
    const [selectedRole, setSelectedRole] = React.useState<string>('כל התפקידים');
    const [selectedSector, setSelectedSector] = React.useState<string>('כל האשכולות');
    const [includeGrade, setSelectGarde] = React.useState('yes');
    const [includeInterviewDate, setSelectInterviewDate] = React.useState('yes');
    const [startDate, setStartDate] = React.useState(dayjs(new Date).set('d', -7));
    const [endDate, setEndDate] = React.useState(dayjs(new Date));
    const [loading, setLoading] = React.useState(false);

    const [roles, setRoles] = React.useState<typeMyData[]>([]);
    const [sectors, setSectors] = React.useState<typeMyData[]>([]);


    React.useEffect(() => {
        const fileData = async () => {
            // ---update sectors         
            let i = 20;
            const sectorsFromDb = await getAllSectors();
            let updatedSectors = [{ id: 10, name: 'כל האשכולות' }];

            updatedSectors = updatedSectors.concat(
                sectorsFromDb.map((sector) => {
                    const sectorObj = { id: i, name: sector._name };
                    i = i + 10
                    return sectorObj;
                })
            );
            setSectors(updatedSectors);

            // ----update roles
            const rolesFromDb = await getAllRoles();
            i = 20;
            let updatedRoles = [{ id: 10, name: "כל התפקידים" }];

            updatedRoles = updatedRoles.concat(
                rolesFromDb.map((role) => {
                    const roleObj = { id: i, name: role._name };
                    i = i + 10;
                    return roleObj;
                })
            );

            setRoles(updatedRoles);
        };
        fileData();
    }, []);


    const createReport = (statusName, timeOnStatus, sectorName, roleName, selectGarde, selectInterviewDate, startDate, endDate) => {
        // checking if the user select all the buttons
        const isDateSelected = startDate && endDate;

        if (!statusName || !timeOnStatus || !sectorName || !roleName || !selectGarde || !isDateSelected) {
            // displaying an error message or indicating to the user that the parameters are mandatory
            alert('יש למלא את כל השדות');
            return;
        }
        const choice = ["כן", "לא"];
        const formattedStartDate = startDate.toDate();
        const formattedEndDate = endDate.toDate();
        setLoading(true);

        const result = CandidatesByFilters(statusName, timeOnStatus, sectorName, roleName, selectGarde, selectInterviewDate, formattedStartDate, formattedEndDate)
            .then((result) => {
                setLoading(false);
                if (result.length === 0) {
                    alert('אין נתונים להצגה');
                    return;
                }
                else {
                    exportToExcel(result, "מועמדים");
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    // handls
    function handleChangeStatus(event: SelectChangeEvent<string>, child: React.ReactNode): void {
        setSelectStatus(event.target.value);
    }

    async function handleChangeTimeInStatus(event: SelectChangeEvent<string>, child: React.ReactNode): Promise<void> {
        setTimeOnStatus(event.target.value);
    }

    const handleChangeSector = (event) => {
        setSelectedSector(event.target.value);
    };

    const handleChangeRole = async (event) => {
        setSelectedRole(event.target.value);
    };

    const handleChangeIncludeGrade = (event) => {
        setSelectGarde(event.target.value);
    };

    const handleChangeIncludeInterviewDate = (event) => {
        setSelectInterviewDate(event.target.value);
    };

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/management/reports");
    };

    return (
        <Stack flex={1} mb={2}>
            <Stack ml={1} mr={1} mb={1} direction='column' gap={1}>
                <Stack m={2} direction='column' gap={1}>
                    <Box display='flex' flexDirection='row' justifyContent='center'>
                        <Avatar variant='circular'
                            sx={{
                                width: { xs: 70, md: 100 },
                                height: { xs: 60, md: '100%' },
                                padding: { xs: 2, md: 3 },
                                background: '#bbcafc',
                            }}
                        >
                            <Person sx={{
                                width: '100%',
                                height: '100%',
                                color: "hsla(0,0%,100%,.7)"
                            }} />
                        </Avatar>
                    </Box>
                    <Typography alignSelf='center' sx={{
                        fontSize: 25,
                        fontFamily: 'Rubik',
                        fontWeight: 600,
                        opacity: 0.8,
                    }}>
                        דו"ח מועמדים
                    </Typography>
                </Stack>
                <Stack direction='row' gap={2}>
                    {/* select the status */}
                    <FormControl fullWidth size="small">
                        <InputLabel>סטטוס</InputLabel>
                        <Select
                            value={status}
                            label="סטטוס"
                            onChange={handleChangeStatus}
                        >
                            <MenuItem value={"כל הסטטוסים"}>כל הסטטוסים</MenuItem>
                            <MenuItem value={'הוגשה מועמדות'}>הוגשה מועמדות</MenuItem>
                            <MenuItem value={"זומן לראיון ראשון"}>זומן לראיון ראשון</MenuItem>
                            <MenuItem value={"עבר ראיון ראשון"}>עבר ראיון ראשון</MenuItem>
                            <MenuItem value={"זומן לראיון שני"}>זומן לראיון שני</MenuItem>
                            <MenuItem value={"עבר ראיון שני"}>עבר ראיון שני</MenuItem>
                            <MenuItem value={"התקבל"}>התקבל</MenuItem>
                            <MenuItem value={"הועבר למשרה אחרת"}>הועבר למשרה אחרת</MenuItem>
                            <MenuItem value={"נדחה"}>נדחה</MenuItem>
                            <MenuItem value={"אינו מעוניין במשרה"}>אינו מעוניין במשרה</MenuItem>
                        </Select>
                        <FormHelperText>מועמדים אשר נמצאים תחת סטטוס ספציפי</FormHelperText>
                    </FormControl>



                    {/* time on status*/}
                    <FormControl fullWidth size="small">
                        <InputLabel>מועמדים שהסטטוס שלהם השתנה</InputLabel>
                        <Select
                            value={timeOnStatus}
                            label="מועמדים שהסטטוס שלהם השתנה"
                            onChange={handleChangeTimeInStatus}
                        >
                            <MenuItem value={'בשבוע האחרון'}>בשבוע האחרון</MenuItem>
                            <MenuItem value={'בחודש האחרון'}>בחודש האחרון</MenuItem>
                            <MenuItem value={'כל המועמדים'}>כל המועמדים</MenuItem>
                        </Select>
                        <FormHelperText>מועמדים אשר חל שינוי בסטטוס שלהם לאחרונה</FormHelperText>
                    </FormControl>
                </Stack>

                <Stack direction='row' gap={2}>
                    {/* sector*/}
                    {sectors?.length > 0 &&
                        <FormControl fullWidth size="small">
                            <InputLabel>אשכול</InputLabel>
                            <Select
                                value={selectedSector}
                                label="אשכול"
                                onChange={handleChangeSector}
                            >
                                {sectors.map((sector) => (
                                    <MenuItem key={sector.id} value={sector.name}>
                                        {sector.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>מועמדים אשר נמצאים תחת אשכול ספציפי</FormHelperText>
                        </FormControl>
                    }

                    {/* select role */}
                    {roles?.length > 0 &&
                        <FormControl fullWidth size="small">
                            <InputLabel>תפקיד</InputLabel>
                            <Select
                                value={selectedRole}
                                label="תפקיד"
                                onChange={handleChangeRole}
                            >
                                {roles.map((role) => (
                                    <MenuItem key={role.id} value={role.name}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>מועמדים אשר הגישו מועמדות לתפקיד ספציפי</FormHelperText>
                        </FormControl>
                    }

                </Stack>




                <Box display='flex' flexDirection='row' justifyContent='space-between' mt={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='he'>
                        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                            <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                label="מתאריך"
                                value={startDate}
                                onChange={handleChangeStartDate}
                            />
                            <Typography alignSelf='center' sx={{
                                fontSize: 15,
                                fontFamily: 'Rubik',
                                fontWeight: 600,
                                letterSpacing: '0px',
                                opacity: 0.8,
                            }}>
                                -
                            </Typography>
                            <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                label="עד תאריך"
                                value={endDate}
                                onChange={handleChangeEndDate}
                            />
                        </Stack>
                    </LocalizationProvider>
                    <Box display='flex' flexDirection='column-reverse'>
                        {/* create report */}
                        <LoadingButton
                            color="info"
                            variant="contained"
                            onClick={() => createReport(status, timeOnStatus, selectedSector, selectedRole, includeGrade, includeInterviewDate, startDate, endDate)}
                            loading={loading}
                        >
                            הנפק דו"ח</LoadingButton>
                    </Box>

                </Box>
            </Stack>

        </Stack >
    );
}

