import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import { Job, getFilteredJobs } from "../Job";
import { Sector, getAllSectors } from "../Sector";

const SectorsByJobsComponent: React.FC = () => {
    const [jobs, setJobs] = useState<Job[]>([]);
    const [sectors, setSectors] = useState<Sector[]>([]);

    useEffect(() => {
        // Load Highcharts libraries
        HighchartsExporting(Highcharts);
        HighchartsExportData(Highcharts);

        const fetchDataAndCreateChart = async () => {
            const filteredJobs = await getFilteredJobs(['open'], ['true']);
            setJobs(filteredJobs); // Update the jobs state with filtered jobs
            const sec = await getAllSectors();
            setSectors(sec);
        };

        fetchDataAndCreateChart();
    }, []);

    useEffect(() => {
        const createChart = () => {
            const jobsBySector: { [sector: string]: number } = {};

            for (let i = 0; i < jobs.length; i++) {
                const job = jobs[i];
                const sector = job._sector;

                if (job._open === true) {
                    if (jobsBySector[sector]) {
                        jobsBySector[sector]++;
                    } else {
                        jobsBySector[sector] = 1;
                    }
                }
            }

            const regions = Object.keys(jobsBySector);
            const quantities = Object.values(jobsBySector);

            Highcharts.chart("chart-container3", {
                chart: {
                    type: "column",
                    renderTo: "chart-container2",
                },
                title: {
                    text: "",
                },
                xAxis: {
                    categories: regions,
                    title: {
                        text: "אשכולות",
                    },
                },
                yAxis: {
                    title: {
                        text: "מספר משרות",
                    },
                },
                series: [
                    {
                        type: "column",
                        name: "מספר משרות",
                        data: quantities,
                        color: "#6666ff",
                    },
                ],
            });
        };

        createChart();
    }, [jobs]);

    return (
        <>
            <style>{`
        #chart-container3 {
          width: 100%;
          height: 400px;
        }
      `}</style>
            <div className="chart-container2">
                <div id="chart-container3"></div>
            </div>
        </>
    );
};

export default SectorsByJobsComponent;
