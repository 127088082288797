import * as XLSX from 'xlsx';

export async function exportToExcel(tableResule: any[], reportName: string) {
    var wscols: any;


    // Convert the result data to the Excel format
    const worksheet = XLSX.utils.json_to_sheet(tableResule);

    if (reportName === 'משרות') {
        wscols = [
            { wch: 9 },  // מס' משרה
            { wch: 16 },  // תפקיד
            { wch: 10 }, // אשכול
            { wch: 18 }, // איזור
            { wch: 10 }, // היקף המשרה
            { wch: 16 }, // משרה בעדיפות גבוהה
            { wch: 18 }, // משרה מיידית / לא מיידית
        ];
    }
    else {
        wscols = [
            { wch: 9 },  // מס' משרה
            { wch: 16 }, // תפקיד
            { wch: 7 },  // שם פרטי
            { wch: 10 }, // שם משפחה
            { wch: 21 }, // מייל
            { wch: 10 }, // פלאפון 
            { wch: 16 }, // איזור
            { wch: 14 }, // איך הגעת אלינו
            { wch: 9 }, // ציון המועמד
            { wch: 14 }, // סטטוס
            { wch: 18 }, // הזמן שעבר משינוי הסטטוס
            { wch: 15 }, // תאריך עדכון הסטטוס
            { wch: 17 }, // תאריך הגשת המועמדות
        ];
    }
    worksheet['!cols'] = wscols;
    const workbook = XLSX.utils.book_new();
    workbook.Workbook = { Views: [{ RTL: true }, { RTL: true }] };
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.download = reportName + '.xlsx';

    // Trigger the download
    downloadLink.click();

}