import SvgLogo from "../../../../Components/Logo/Logo.svg"
import { Avatar, Backdrop, Box, Stack, Typography } from "@mui/material";
import "./MyLoading.css"
import React, { useEffect } from "react";

//    const [loading, setLoading] = React.useState(true);
const MyLoading = (props: { loading: any, setLoading: any }) => {
    const { loading, setLoading } = props;

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 1000);
    // }, []);


    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center' }}>
            <Stack direction='column' spacing={3} justifyContent='center'>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}>
                    <Avatar variant='circular' className="rotate_03" src={SvgLogo} sx={{
                        border: '1px solid #dee2e6', width: 90, height: 90,
                        '& .MuiAvatar-img': {
                            objectFit: 'contain',
                        }
                    }} />

                    <Stack direction='column' spacing={1}>
                        <Typography color='primary.drushimTitle' variant='h2' textAlign='center'>
                            הנתונים נקלטים במערכת
                        </Typography>

                        <Typography variant='body2' color='secondary.drushimTitle' textAlign='center'>
                            לתשומת ליבך, פעולה זו עלולה לקחת מס' דקות.
                        </Typography>
                    </Stack>
                </Backdrop>

            </Stack>
        </Box>
    );

};

export default MyLoading;