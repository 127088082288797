import { ArticleOutlined } from "@mui/icons-material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Container, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import CandidatesByFilters from '../../../../Firebase/FirebaseFunctions/Reports/CandidatesFilters';
import { exportToExcel } from '../../../../Firebase/FirebaseFunctions/Reports/GlobalFunctions';
import { Candidate, getAllRoles, getAllSectors, getFilteredCandidateJobStatuses, getFilteredCandidates } from '../../../../Firebase/FirebaseFunctions/functionIndex';
import { BoxGradientSx, MyPaperSx } from '../../../ManageJobsPage/Components/NewJobPage/NewJobStyle';
import { designReturnButton } from '../../../ManageJobsPage/ManageJobsPageStyle';
import { MyReportStyle, radioStyle } from '../../ReportPageStyle';
import dayjs from "dayjs";
import MyLoading from "../../../../Components/MyLoading/MyLoading";
import CandidatesForm from "../CandidatesForm/CandidatesForm";

interface typeMyData {
    id: number;
    name: string;
}

export default function CandidateFiltersForm() {
    const [status, setSelectStatus] = React.useState('');
    const [timeOnStatus, setTimeOnStatus] = React.useState('');
    const [roles, setRoles] = React.useState<typeMyData[]>([]);
    const [sectors, setSectors] = React.useState<typeMyData[]>([]);
    const [selectedRole, setSelectedRole] = React.useState<string>();
    const [selectedSector, setSelectedSector] = React.useState<string>();
    const [includeGrade, setSelectGarde] = React.useState('yes');
    const [includeInterviewDate, setSelectInterviewDate] = React.useState('yes');
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [loading, setLoading] = React.useState(false);


    React.useEffect(() => {
        const fileData = async () => {
            // ---update sectors         
            let i = 20;
            const sectorsFromDb = await getAllSectors();
            let updatedSectors = [{ id: 10, name: 'כל האשכולות' }];

            updatedSectors = updatedSectors.concat(
                sectorsFromDb.map((sector) => {
                    const sectorObj = { id: i, name: sector._name };
                    i = i + 10
                    return sectorObj;
                })
            );
            setSectors(updatedSectors);

            // ----update roles
            const rolesFromDb = await getAllRoles();
            i = 20;
            let updatedRoles = [{ id: 10, name: "כל התפקידים" }];

            updatedRoles = updatedRoles.concat(
                rolesFromDb.map((role) => {
                    const roleObj = { id: i, name: role._name };
                    i = i + 10;
                    return roleObj;
                })
            );

            setRoles(updatedRoles);
        };
        fileData();
    }, []);

    const createReport = (statusName, timeOnStatus, sectorName, roleName, selectGarde, selectInterviewDate, startDate, endDate) => {
        // checking if the user select all the buttons
        const isDateSelected = startDate && endDate;

        if (!statusName || !timeOnStatus || !sectorName || !roleName || !selectGarde || !isDateSelected) {
            // displaying an error message or indicating to the user that the parameters are mandatory
            alert('יש למלא את כל השדות');
            return;
        }
        const choice = ["כן", "לא"];
        const formattedStartDate = startDate.toDate();
        const formattedEndDate = endDate.toDate();
        setLoading(true);

        const result = CandidatesByFilters(statusName, timeOnStatus, sectorName, roleName, selectGarde, selectInterviewDate, formattedStartDate, formattedEndDate)
            .then((result) => {
                setLoading(false);
                if (result.length === 0) {
                    alert('אין נתונים להצגה');
                    return;
                }
                else {
                    exportToExcel(result, "מועמדים");
                    console.log(result);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    // handls
    function handleChangeStatus(event: SelectChangeEvent<string>, child: React.ReactNode): void {
        setSelectStatus(event.target.value);
    }

    async function handleChangeTimeInStatus(event: SelectChangeEvent<string>, child: React.ReactNode): Promise<void> {
        setTimeOnStatus(event.target.value);
    }

    const handleChangeSector = (event) => {
        setSelectedSector(event.target.value);
    };

    const handleChangeRole = async (event) => {
        setSelectedRole(event.target.value);
    };

    const handleChangeIncludeGrade = (event) => {
        setSelectGarde(event.target.value);
    };

    const handleChangeIncludeInterviewDate = (event) => {
        setSelectInterviewDate(event.target.value);
    };

    const handleChangeStartDate = (date) => {
        setStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/management/reports");
    };

    return (
        <>
            {loading ? (
                <MyLoading loading={loading} setLoading={setLoading} />
            ) :
                (
                    <>
                        <Stack >
                            <Box sx={BoxGradientSx}>
                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    right: '2%',
                                    left: 'auto',
                                    top: '15%',
                                    bottom: 'auto',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />

                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    right: '10%',
                                    left: 'auto',
                                    top: '0%',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '170px',
                                    height: '170px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />

                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    left: '40%',
                                    top: '-1%',
                                    right: 'auto',
                                    bottom: 'auto',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />


                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    left: 'auto',
                                    top: '16%',
                                    bottom: 'auto',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />

                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    left: '-2%',
                                    top: '12%',
                                    bottom: 'auto',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '120px',
                                    height: '120px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />

                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    left: '4%',
                                    top: '8%',
                                    bottom: 'auto',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '80px',
                                    height: '80px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />

                                <Box display={{ xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }} sx={{
                                    left: '25%',
                                    top: '12%',
                                    bottom: 'auto',
                                    backgroundColor: 'hsla(0,0%,100%,.1)',
                                    background: 'hsla(0,0%,100%,.1)',
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%',
                                    position: 'absolute',
                                }} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', top: "165px", position: "absolute" }}>
                                    <Stack direction='column'>
                                        <Stack direction='row' justifyContent='center' spacing={1}>

                                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <ArticleOutlined sx={{ color: '#fff' }} />
                                            </Box>
                                            <Typography sx={{ fontFamily: "'Noto Sans Hebrew', sans-serif", color: '#fff', textAlign: 'center' }} variant='h4'>
                                                דו"ח מועמדים
                                            </Typography>

                                        </Stack>

                                        <Typography sx={{ opacity: 0.6, width: '100%', textAlign: 'center', color: '#fff', fontSize: '16px', fontFamily: "'Noto Sans Hebrew', sans-serif", mt: 1 }} variant='subtitle1'>
                                            הפקת דוחות על מועמדים לפי מס' קטגוריות
                                        </Typography>
                                        <Box sx={{ background: 'linear-gradient(90deg,hsla(0,0%,100%,0),#fff,hsla(0,0%,100%,0))', padding: 0.05, width: '100%', mt: 2 }} />
                                    </Stack>

                                </Box>
                            </Box >

                            <Box sx={MyPaperSx}>

                                <Stack m={2} direction='column' gap={1} style={{ borderStyle: 'solid' }}>
                                    <Stack direction='row' gap={2}>
                                        {/* select the status */}
                                        <FormControl fullWidth size="small">
                                            <InputLabel>סטטוס</InputLabel>
                                            <Select
                                                value={status}
                                                label="סטטוס"
                                                onChange={handleChangeStatus}
                                            >
                                                <MenuItem value={"כל הסטטוסים"}>כל הסטטוסים</MenuItem>
                                                <MenuItem value={'הוגשה מועמדות'}>הוגשה מועמדות</MenuItem>
                                                <MenuItem value={"זומן לראיון ראשון"}>זומן לראיון ראשון</MenuItem>
                                                <MenuItem value={"עבר ראיון ראשון"}>עבר ראיון ראשון</MenuItem>
                                                <MenuItem value={"זומן לראיון שני"}>זומן לראיון שני</MenuItem>
                                                <MenuItem value={"עבר ראיון שני"}>עבר ראיון שני</MenuItem>
                                                <MenuItem value={"התקבל"}>התקבל</MenuItem>
                                                <MenuItem value={"הועבר למשרה אחרת"}>הועבר למשרה אחרת</MenuItem>
                                                <MenuItem value={"נדחה"}>נדחה</MenuItem>
                                                <MenuItem value={"אינו מעוניין במשרה"}>אינו מעוניין במשרה</MenuItem>
                                            </Select>
                                            <FormHelperText>מועמדים אשר נמצאים תחת סטטוס ספציפי</FormHelperText>
                                        </FormControl>



                                        {/* time on status*/}
                                        <FormControl fullWidth size="small">
                                            <InputLabel>מועמדים שהסטטוס שלהם השתנה</InputLabel>
                                            <Select
                                                value={timeOnStatus}
                                                label="מועמדים שהסטטוס שלהם השתנה"
                                                onChange={handleChangeTimeInStatus}
                                            >
                                                <MenuItem value={'עד שבוע'}>עד שבוע</MenuItem>
                                                <MenuItem value={'עד חודש'}>עד חודש</MenuItem>
                                                <MenuItem value={'כל זמן'}>כולם</MenuItem>
                                            </Select>
                                            <FormHelperText>מועמדים אשר חל שינוי בסטטוס שלהם לאחרונה</FormHelperText>
                                        </FormControl>
                                    </Stack>
                                    <Stack direction='row' gap={2}>
                                        {/* sector*/}
                                        <FormControl fullWidth size="small">
                                            <InputLabel>אשכול</InputLabel>
                                            <Select
                                                value={selectedSector}
                                                label="אשכול"
                                                onChange={handleChangeSector}
                                            >
                                                {sectors.map((sector) => (
                                                    <MenuItem key={sector.id} value={sector.name}>
                                                        {sector.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>מועמדים אשר נמצאים תחת אשכול ספציפי</FormHelperText>
                                        </FormControl>


                                        {/* select role */}
                                        <FormControl fullWidth size="small">
                                            <InputLabel>תפקיד</InputLabel>
                                            <Select
                                                value={selectedRole}
                                                label="תפקיד"
                                                onChange={handleChangeRole}
                                            >
                                                {roles.map((role) => (
                                                    <MenuItem key={role.id} value={role.name}>
                                                        {role.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>מועמדים אשר הגישו מועמדות לתפקיד ספציפי</FormHelperText>
                                        </FormControl>

                                    </Stack>
                                    {/* select include grade */}
                                    {/* <RadioGroup
                                        value={includeGrade}
                                        onChange={handleChangeIncludeGrade}
                                    >
                                        <div style={radioStyle}>
                                            <FormControlLabel value="yes" control={<Radio />} label="כלול ציון המועמד" />
                                            <FormControlLabel value="no" control={<Radio />} label="אל תכלול" />
                                        </div>
                                    </RadioGroup>
                          */}

                                    {/* select include mathcing rate */}
                                    {/* <RadioGroup
                                        value={includeInterviewDate}
                                        onChange={handleChangeIncludeInterviewDate}
                                        aria-label="include-grade"
                                        name="include-grade"
                                    >
                                        <div style={radioStyle}>
                                            <FormControlLabel value="yes" control={<Radio />} label="כלול במידה ונקבע את תאריך הראיון הבא\האחרון שלו" />
                                            <FormControlLabel value="no" control={<Radio />} label="אל תכלול" />
                                        </div>
                                    </RadioGroup> */}

                                    {/* <br /> */}
                                    {/* select time */}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                                            <DatePicker

                                                label="מתאריך"
                                                value={startDate}
                                                onChange={handleChangeStartDate}
                                            />
                                            <DatePicker
                                                label="עד תאריך"
                                                value={endDate}
                                                onChange={handleChangeEndDate}
                                            />
                                        </Stack>
                                    </LocalizationProvider>

                                </Stack>

                                <Box display='flex' flexDirection='row-reverse'>
                                    {/* create report */}
                                <Button variant="contained" onClick={() => createReport(status, timeOnStatus, selectedSector, selectedRole, includeGrade, includeInterviewDate, startDate, endDate)}>הנפק דו"ח</Button>

                                </Box>

                            </Box>

                            <Box style={{ position: 'absolute', top: '100px', right: '50px' }}>
                                <Button
                                    onClick={handleClick}
                                    sx={designReturnButton}
                                >
                                    <ArrowForwardIosIcon></ArrowForwardIosIcon>
                                    חזור
                                </Button>

                            </Box>
                        </Stack >
                    </>
                )}
        </>
    );
}

