import React, { useEffect, useState } from "react";
import { Candidate } from "../../../../../Firebase/FirebaseFunctions/Candidate";
import { CandidateJobStatus, getFilteredCandidateJobStatuses } from "../../../../../Firebase/FirebaseFunctions/CandidateJobStatus";

export default function ArrivalWay(props: {candidate: Candidate | null, jobId: string }) {
    const {candidate, jobId } = props;
    const [candidateJobStatus, setCandidateJobStatus] = useState<CandidateJobStatus | null>(null);
    const fetchCandidateJobStatus = async () =>
    {
        setCandidateJobStatus((await getFilteredCandidateJobStatuses(["jobNumber", "candidateId"], [jobId, candidate?._id!]))[0]);
    }

    useEffect(() =>
    {
        fetchCandidateJobStatus();
    }, [jobId]);


  return <div>{candidateJobStatus?._arrivalWay!}</div>;
}
