import { Stack } from '@mui/material'
import React from 'react'
import CandidatesTable from '../CandidatesTable/CandidatesTable'
import MyLoading from '../../../../../../Components/MyLoading/MyLoading';
import { getFilteredJobs } from '../../../../../../Firebase/FirebaseFunctions/Job';
import JobItem from '../JobItem/JobItem';

export default function DialogPage(props: { JobId: any }) {
    const { JobId } = props;
    const [pageloading, setPageLoading] = React.useState(true);
    const [rows, setRows] = React.useState<any>([]);

    const fetchJob = async () => {
        setRows(await getFilteredJobs(['jobNumber'], [`${JobId}`]));
    };

    React.useEffect(() => {
        setPageLoading(false);
        fetchJob();
    }, []);
    return (
        <>
            { pageloading ? (<MyLoading loading={pageloading} setLoading={setPageLoading} />) 
            : 
            (
                <Stack height='100%' direction='column' justifyContent='space-between' pt={3}>
                    <JobItem job={rows.length > 0 ? rows[0] : null} />
                    <CandidatesTable JobId={JobId} />
                </Stack>
            )
            }
        </>
    )
}
