import { Box, Stack } from "@mui/material";
import React from "react";
import { BoxGradientSx } from "../../PageStyles";
import JobItem from "../../ManageJobsPage/Components/CandidatesListDialog/Components/JobItem/JobItem";
import { Candidate, getFilteredCandidates } from "../../../Firebase/FirebaseFunctions/Candidate";
import CandidateCard from "./CandidateCard/CandidateCard";
import JobsTable from "./JobsTable/JobsTable";
import { Job, getFilteredJobs } from "../../../Firebase/FirebaseFunctions/Job";
import { useLocation, useNavigate } from "react-router-dom";
import { getFilteredCandidateJobStatuses } from "../../../Firebase/FirebaseFunctions/CandidateJobStatus";
import AboutDialog from "../ViewCandidatesPage/Components/AboutDialog/AboutDialog";
import RecommendersDialog from "../ViewCandidatesPage/Components/RecommendersDialog/RecommendersDialog";



export default function ViewCandidatePage(props: { candidateId: string }) {
    const { candidateId } = props;

    const [loading, setLoading] = React.useState(true);
    const navigate = useNavigate();
    // candidate and jobs info
    const [candidate, setCandidate] = React.useState<Candidate>();
    const [candidateJobs, setCandidateJobs] = React.useState<Job[]>([]);
    // AboutDialog
    const [aboutDialogOpen, setAboutDialogOpen] = React.useState(false);
    const [aboutDialogJobId, setAboutDialogJobId] = React.useState("");
    // onClose for AboutDialog
    const aboutDialogonClose = (event, reason) => {
        if ((reason && reason !== "backdropClick") || reason === undefined) {
            setAboutDialogOpen(false);
        }
    }

    // RecommendersDialog
    const [recommendersDialogOpen, setRecommendersDialogOpen] = React.useState(false);
    const [recommendersDialogJobId, setRecommendersDialogJobId] = React.useState("");

    const closeRecommendersDialog = (event, reason) => {
        if ((reason && reason !== "backdropClick") || reason === undefined) {
            setRecommendersDialogOpen(false);
        }
    }

    const { state } = useLocation();


    const getJobs = async function (candidateId: string, setCandidateJobs) {
        // get a list of all job numbers
        // for the jobs this candidate applied to
        const candidateJobStatuses = await getFilteredCandidateJobStatuses(["candidateId"], [candidateId]);
        let jobNumbers: number[] = [];
        candidateJobStatuses.forEach(element => {
            jobNumbers.push(element._jobNumber);
        });

        // get all jobs from firebase
        let jobs = await getFilteredJobs();

        // filter them by the list of job numbers 
        // we got from the previous request to firebase
        jobs = jobs.filter(job => {
            return jobNumbers.includes(job._jobNumber);
        });
        setCandidateJobs(jobs)
    }


    const fetchCandidate = async () => {
        const candidate_ = await getFilteredCandidates(['id'], [`${candidateId}`]);
        if (candidate_?.length != 1) { console.log("ViewCandidatePage error"); }
        else { setCandidate(candidate_[0]); }
    };
    React.useEffect(() => {
        // setPageLoading(false);
        fetchCandidate();
    }, []);


    React.useEffect(() => {
        // pull candidate from firebase
        fetchCandidate();

        // get list of all jobs for this candidate
        getJobs(candidateId, setCandidateJobs);
    }, [candidateId])

    React.useEffect(() => {
        fetchCandidate();
    }, [candidate]);

    return (
        <>
            <Box sx={BoxGradientSx}>
                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        right: "2%",
                        left: "auto",
                        top: "15%",
                        bottom: "auto",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />

                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        right: "10%",
                        left: "auto",
                        top: "0%",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "170px",
                        height: "170px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />

                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        left: "40%",
                        top: "-1%",
                        right: "auto",
                        bottom: "auto",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />

                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        left: "auto",
                        top: "16%",
                        bottom: "auto",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />

                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        left: "-2%",
                        top: "12%",
                        bottom: "auto",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "120px",
                        height: "120px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />

                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        left: "4%",
                        top: "8%",
                        bottom: "auto",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />

                <Box
                    display={{
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                    }}
                    sx={{
                        left: "25%",
                        top: "12%",
                        bottom: "auto",
                        backgroundColor: "hsla(0,0%,100%,.1)",
                        background: "hsla(0,0%,100%,.1)",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        position: "absolute",
                    }}
                />
            </Box>
            {/* Dialogs */}
            <AboutDialog open={aboutDialogOpen} onClose={aboutDialogonClose} candidate={candidate!} jobId={aboutDialogJobId} />

            <RecommendersDialog
                open={recommendersDialogOpen}
                onClose={closeRecommendersDialog}
                jobId={recommendersDialogJobId}
                candidateId={candidate?._id!}
                setLoading={setLoading}
            />
            <Stack height={{md: 'calc(100vh - 250px)'}} direction={'column'} gap={1}>
                <Box sx={{ padding: 1, mt: -14 }}>
                    <CandidateCard candidate={candidate} />
                </Box>

                <JobsTable
                    candidateJobs={candidateJobs}
                    candidateInfo={candidate!}
                    // about dialog
                    setAboutDialogOpen={setAboutDialogOpen}
                    aboutDialogOnClose={aboutDialogonClose}
                    setAboutDialogJobId={setAboutDialogJobId}
                    // recommenders dialog
                    setRecommendersDialogOpen={setRecommendersDialogOpen}
                    setRecommendersDialogJobId={setRecommendersDialogJobId}
                    closeRecommendersDialog={closeRecommendersDialog} />
            </Stack>
        </>

    );
}
