import { Box, CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Job, getFilteredJobs } from '../Firebase/FirebaseFunctions/functionIndex';
import AllJobsPage from './AllJobsPage/AllJobsPage';
import Accessibility from './Components/Accessibility/Accessibility';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import OneJobPage from './OneJobPage/OneJobPage';
import PageNotFound from '../Components/PageNotFound/PageNotFound';
import AllJobsPageCampaign from './AllJobsPageCampaign/AllJobsPageCampaign';
import ThanksPage from './AllJobsPage/Components/ThanksPage/ThanksPage';

export default function DrushimMainPage() {
	const [jobs, setJobs] = useState<Job[]>([]);

	const fetchJobs = async () => {
		setJobs(await getFilteredJobs(["open"], ["true"]));
	}

	useEffect(() => {
		fetchJobs();
	}, [])

	return (
		<>
			{/* All Jobs Page Route*/}
			<CssBaseline />
			<Navbar />
			<Box flex={1} height={'calc(100vh - 64px)'}>
				<Accessibility />
				<Routes>
					{/* All Jobs Page Route*/}
					<Route path='jobs' element={<AllJobsPage />} />
					<Route path='jobs_campaign' element={<AllJobsPageCampaign />} />
					<Route path='thanks' element={<ThanksPage />}/>
					{/* One Job Pages Routes */}
					{jobs.map(job => <Route path={'jobs/' + job._jobNumber} element={<OneJobPage />} key={job._jobNumber} />)}
					<Route path="*" element={<PageNotFound />} />
				</Routes>
				<Footer />
			</Box>
		</>
	)
}