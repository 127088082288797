import { Box, CircularProgress, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import MyCircularProgress from '../../../../Components/MyCircularProgress/MyCircularProgress';
import { useLocation } from 'react-router-dom';
import "./ThanksPage.css";
import { Verified } from '@mui/icons-material';

export default function ThanksPage() {
    const { state } = useLocation();
    
    const [homePage, setHomePage] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() =>
    {
        // setTimeout(()=>{
        //     setLoading(false);
        //    }, 1500)
        setLoading(false);
        setHomePage(state?.homePath != null && state?.homePath != undefined ? state?.homePath : '/career/jobs');
        
    }, [])
    return (
        <>
        {loading ? <MyCircularProgress /> : <>
        <Container maxWidth='md' sx={{padding: 2, flex:1, height: '100%', display:'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Box display='flex' justifyContent='center'>
            <Stack
                paddingTop={4}
                paddingBottom={4}
                direction='column'
                width='100%'
                spacing={4}
                sx={{
                    color: 'primary.myBoxShadow',
                }}
            >

                <Stack direction='column' spacing={1}>
                    <Typography color='primary.drushimTitle' variant='h2' textAlign='center'>
                        מועמדותך נקלטה בהצלחה!
                    </Typography>

                    <Typography variant='body2' color='secondary.drushimTitle' textAlign='center'>
                        {"לחזרה לדף המשרות "}
                        <a className='a' target='_self' style={{color: 'inherit', textDecoration: 'none'}} href={`${homePage}`}>
                                     לחץ כאן 
                                    </a>.
                    </Typography>
                </Stack>
            </Stack>

        </Box>
    </Container>
        </>}
        </>

    )
}
